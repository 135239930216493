<template>
  <div class="hello">
    111<br>
    {{ msg }}<br>
    333<br>
    slot1:
    <div @click="slot1Clink">
      <slot name="slot1"></slot>
    </div>
    slot2:
    <slot name="slot2"></slot>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  name: 'TestComponent',
  props: {
    msg: String
  },
  methods: {
    slot1Clink(){
      Message.success(222);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
