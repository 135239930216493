<template>
  <div id="app">
    <div class="IRouter">
      <div class="homeLogo"><img src="//file.tumuxiangye.com/guanWang/img/logo1.png" /></div>
      <nav>
        <router-link to="/" :class="routerPath=='/'?'router-link-exact-active router-link-active':''">
          <div class="routerItem">首页</div>
        </router-link>
        <router-link to="/anli/list" :class="(routerPath=='/anli/list' || routerPath=='/anli/info')?'router-link-exact-active router-link-active':''">
          <div class="routerItem" v-on:mouseenter="onmouseenter" v-on:mouseleave="onmouseleave">
            <div>实景案例</div>
            <div v-if="routerAnLi.subDisplay" class="subRouterItem" style="position:absolute; z-index: 100;">
              <router-link to="/anli/list?type=0" :class="routerAnLi.type==0?'active':''">全部案例</router-link>
              <!--
              <router-link to="/anli/list?type=1" :class="routerAnLi.type==1?'active':''">整包方案</router-link>
              -->
              <router-link to="/anli/list?type=2" :class="routerAnLi.type==2?'active':''">精品案例</router-link>
              <router-link to="/anli/list?type=3" :class="routerAnLi.type==3?'active':''">局部改造</router-link>
              <router-link to="/anli/list?type=4" :class="routerAnLi.type==4?'active':''">新建案例</router-link>
            </div>
          </div>
        </router-link>
        <router-link to="/shejishi/list" :class="(routerPath=='/shejishi/list' || routerPath=='/shejishi/info')?'router-link-exact-active router-link-active':''">
          <div class="routerItem">设计师</div>
        </router-link>
        <router-link to="/shigongdui/list" :class="(routerPath=='/shigongdui/list' || routerPath=='/shigongdui/info')?'router-link-exact-active router-link-active':''">
          <div class="routerItem">工程施工</div>
        </router-link>
        <router-link to="/youxuan">
          <div class="routerItem">优选整装</div>
        </router-link>
        <router-link to="/about">
          <div class="routerItem">关于我们</div>
        </router-link>
      </nav>
      <div class="topPhone">
        <img src="/img/phoneIcon.png"  />
        <span> 18293837210</span>
      </div>
      <div style="clear: both"></div>
    </div>
    <router-view />
    <div class="futter">
      <div class="futter2">
        <div class="futter3 first">
          <router-link to="/about">关于我们</router-link>
        </div>
        <div class="futter3"><router-link to="/about">企业文化</router-link></div>
        <div class="futter3"><router-link to="/about">联系我们</router-link></div>
      </div>
      <div class="futter2">
        <div class="futter3 first">
          装修服务
        </div>
        <div class="futter3"><router-link to="/shejishi/list">找设计师</router-link></div>
        <div class="futter3"><router-link to="/shigongdui/list">找施工队</router-link></div>
      </div>
      <div class="futter2">
        <div class="futter3 first">
          <router-link to="/anli/list?type=0">案例展示</router-link>
        </div>
        <div class="futter3"><router-link to="/anli/list?type=4">整包案例</router-link></div>
        <div class="futter3"><router-link to="/anli/list?type=3">改造案例</router-link></div>
      </div>
      <div class="futter2">
        <div class="futter3 first">
          土木乡野小程序
        </div>
        <div class="futter3" style="cursor: pointer;" @click="QRCodeDialogData.type=1;QRCodeDialogData.visible=true">使用小程序</div>
        <div class="futter3" style="cursor: pointer;" @click="QRCodeDialogData.type=2;QRCodeDialogData.visible=true">AI免费设计</div>
      </div>
    </div>

    <!--预约弹窗-->
    <YuYueDialog v-model="YuYueDialogData"></YuYueDialog>
    <!--显示二维码-->
    <QRCodeDialog v-model="QRCodeDialogData"></QRCodeDialog>
  </div>
</template>
<script>
export default {
  name: "YourComponent",
  methods: {
    onmouseenter:function(){
      this.$data.routerAnLi.subDisplay = true;
    },
    onmouseleave: function(){
      this.$data.routerAnLi.subDisplay = false;
    }
  },
  watch: {
    '$route'(to, from) {
      // 当路由发生变化时，这个函数会被调用
      // 'to' 和 'from' 是两个路由对象，分别代表将要进入的路由和当前导航正要离开的路由
      //console.log('路由变化了:', from, '->', to);
      // 在这里可以根据路由变化执行相应的逻辑
      this.$data.routerPath = to.path;
      if(to.path == "/anli/list"){
        this.$data.routerPath = to.path;
        if(typeof to.query.type != 'undefined'){
          this.$data.routerAnLi.type = to.query.type;
        }else{
          this.$data.routerAnLi.type = 0;
        }
      }else{
        this.$data.routerAnLi.type = null;
      }
    }
  },
  mounted() {
    //设置路由地址
    this.$data.routerPath = this.$route.path;
    if(this.$route.path == "/anli/list"){
      this.$data.routerPath = this.$route.path;
      if(typeof this.$route.query.type != 'undefined'){
        this.$data.routerAnLi.type = this.$route.query.type;
      }else{
        this.$data.routerAnLi.type = 0;
      }
    }else{
      this.$data.routerAnLi.type = null;
    }
    //rem适配
    const script = document.createElement("script");
    script.src = "/js/rem.js"; // 替换为你的脚本URL
    script.onload = () => {
      // 脚本加载完毕后的回调函数
      console.log("Script loaded successfully.");
    };
    document.body.appendChild(script);
  },
  data() {
    return {
      QRCodeDialogData:{
        type:1,
        visible:false
      },
      //预约Dialog
      YuYueDialogData:{
        visible:false,
        id:0,//设计师id或施工队id
        type:4//0:首页,1 设计师,2施工队,3 优选整装，4底部AI免费设计
      },
      routerAnLi:{
        subDisplay:false,
        type: -1
      },
      routerPath:""//存储当前路由path
    }
  }

};
</script>
<style>
body {
  padding: 0px;
  margin: 0px;
  min-width1111111: 1100px;
}

#app {
  font-size: 20px;
  font-family: PingFang HK-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.IRouter {
  height: 82px;
  overflow: hidden;
}

.homeLogo {
  float: left;
  padding-left: 1.51rem;
  padding-top: 31px;
}
@media screen and (max-width: 1100px) {
  .homeLogo1111 {
    padding-left: 0.51rem;
  }
  nav1111 {
    margin-left: 0.5rem !important;
  }
}
.routerItem {
  float: left;
  font-size: 0.21rem;
  line-height: 47px;
  margin-left: 0.4rem;
  padding: 0px 10px 0px 10px;
  border-bottom: 5px solid #ffffff;
}
.routerItem .subRouterItem{
  width: 13.2rem;
  height: 1.17rem;
  line-height: 1.17rem;
  background-color: #ffffff;
  border-top: 1px solid #707070;
  margin-top: 5px;
  color: #333333;
  left: 0px;
  padding-left: 6rem;
}
.routerItem .subRouterItem .active{
  font-weight: 600;
}
.routerItem .subRouterItem a{
  color: #333333;
  font-size: 0.21rem;
  margin-left: 0.8rem;
}
.topPhone {
  float: right;
  padding-right: 0.52rem;
  font-size: 0.24rem;
  font-weight: 600;
  color: #ff6c00;
  padding-top: 40px;
}
.topPhone  img{
  width: 0.19rem;
  height: 0.19rem;
}
nav {
  float: left;
  padding-top: 30px;
  margin-left: 1rem;
}

nav a {
  text-decoration: none;
  color: #333333;
  padding-top: 0.55rem;
}

nav a.router-link-exact-active .routerItem {
  border-bottom: 5px solid #1c9a0f;
}

.futter {
  margin-top: 0.78rem;
  font-family: Source Han Sans CN VF, Source Han Sans CN VF;
  width: 19.2rem;
  height: 2.73rem;
  background: #1f1f1e;
  color: #ffffff;

  line-height: 0.5rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.futter .futter2 {
  float: left;
  width: 4.8rem;
  padding-top: 0.69rem;
}
.futter .futter2 .futter3 {
  padding-left: 1.1rem;
  opacity: 0.7;
  font-size: 0.19rem;
}
.futter .futter2 .first {
  font-size: 0.25rem;
  opacity: 1;
}
.futter .futter2 a {
  text-decoration: none;
  color: #ffffff;
}
</style>
